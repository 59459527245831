// src/App.js
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import profilePic from './img/DuncanSkilton.jpg';
import { Routes, Route } from 'react-router-dom';
import { Container, Row, Col, Image, } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; 
import Header from './components/Header';
import Footer from './components/Footer';
import MyStack from './features/stack/MyStack';
import MyExperience from './features/experience/MyExperience';

const Home = () => (
  <>
    <Row className='main-body'> 
      <Col className="align-items-start" xs={12} md={3} lg={2}>
        <Image src={profilePic} className="profile-image" square alt="Duncan Skilton" fluid />
      </Col>
      <Col className="align-items-start" xs={12} md={9} lg={10}>
        <h3>About Me</h3>
        <p>I'm Duncan, a Java Software Developer with a genuine passion for technology and a commitment to my work. My career is characterized by a continual thirst for knowledge and an understanding of software development principles. Colleagues recognize me for always being willing to help, as well as for my adaptability, determination to tackle complex challenges, and the ability to swiftly learn and apply new technologies and concepts. I produce high-quality and robust code</p>
        <p>My journey in software development has equipped me with a solid foundation in Java, Spring Boot, Hibernate, and MySQL, enabling me to contribute effectively to both the creation and enhancement of robust, scalable online platforms. I excel in environments that demand precision, such as managing complex JPA/Hibernate setups and navigating extensive codebases.</p>
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <p>What truly sets me apart is my eagerness to share knowledge with my peers and my proactive approach to problem-solving, whether it's diving deep into the code to resolve defects or going the extra mile to deliver features under tight deadlines. My desire to learn is matched only by my drive to deliver, making me a reliable and enthusiastic team player who's always ready to contribute to our collective success.</p>
        <p>In seeking long-term employment, I aim to join a team where I can continue to grow, share my expertise, and contribute to meaningful projects. Please feel free to reach out to me via LinkedIn, link is below.</p>
      </Col>
    </Row>  
  </>
);

function App() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.initialize('G-CS9GBGT6JM');
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <>
    <Header />
    <Container>
      <hr />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/stack' element={<MyStack />} />
        <Route path='/experience' element={<MyExperience />} />
      </Routes>    
      <hr />
    </Container>
    <Footer />
    </>
  );
}

export default App;