// src/components/Footer.js
import React from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer className="footer bg-dark text-light py-3">
      <Container>
        <Row>
          <Col xs={12} className="text-center">
            <Nav className="justify-content-center">
              <Nav.Link href="https://www.linkedin.com/in/duncan-skilton-4603903a/" target="_blank" className="text-white">LinkedIn</Nav.Link>
              <Nav.Link href="https://github.com/dskilton" target="_blank" className="text-white">Github</Nav.Link>
              <Nav.Link href="https://www.instagram.com/duncancodes/" target="_blank" className="text-white">Instagram</Nav.Link>
              <Nav.Link href="https://twitter.com/duncanCodes_" target="_blank" className="text-white">Twitter / X</Nav.Link>
            </Nav>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;