// src/features/stack/MyStack.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'; // Ensure Div is removed if not used

const MyStack = () => {
  return (
    <Container>
      <Row className="my-5">
        <Col xs={12} md={6}>
          <h2>Current Employer</h2>
          <p>Java, Spring Boot, Hibernate, JPA, MySQL, Liquibase</p>
          <p>Docker, Jenkins then moved to CodeDeploy. </p>
          <p>BitBucket, Git Bash, Jira, Slack, Teams</p>
        </Col>
        <Col xs={12} md={6}>
          <h2>Personal Projects</h2>
          <h4>This website</h4>
          <p>React/ Next.js, json, npm, Git, Github</p>
          <p>Self hosted using ovhCloud</p> 
          <h6>Features to implement</h6>
          <p>Spring Boot, Jenkins</p>
        </Col>
      </Row>
      <Row className="my-5">
      <Col xs={12} md={6}>
          <h2>Previous Employers</h2>
          <p>Java, Spring Boot, Hibernate, JPA, AWS DynamoDB</p>
          <p>Git Bash</p>
        </Col>
        <Col xs={12} md={6}>
          <a href="https://github.com/DSkilton/LibraryManagementSystem" class="github-link" rel="noreferrer" target="_blank"><h4>Design Patterns Project</h4></a>
          <p>Java, Spring Boot, Hibernate and H2, Docker, Jenkins, Git, Github, Trello</p>
          <h6>Features to implement</h6>
          <p>Kubernetes, SonarQube, Grafana</p>
          <p></p>
        </Col>
      </Row>
      <Row className="my-5">
        <Col xs={12} md={6}>
        </Col>
        <Col xs={12} md={6}>
          <h4>MIS Project</h4>
          <p>Java, Spring Boot, AWS DynamoDB, IAM, Git, Github, Trello</p>
          <h6>Features to implement</h6>
          <p>SonarQube, Jenkins, Grafana, ELK</p>
          <p></p>
        </Col>
      </Row>
    </Container>
  );
};

export default MyStack;
