// src/features/stack/stackSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentEmployerStack: [],
  personalProjectsStack: [],
  previousEmployersStack: [],
};

const stackSlice = createSlice({
  name: 'stack',
  initialState,
  reducers: {
    setCurrentEmployerStack: (state, action) => {
      state.currentEmployerStack = action.payload;
    },
    setPersonalProjectsStack: (state, action) => {
      state.personalProjectsStack = action.payload;
    },
    setPreviousEmployersStack: (state, action) => {
      state.previousEmployersStack = action.payload;
    },
  },
});

export const { setCurrentEmployerStack, setPersonalProjectsStack, setPreviousEmployersStack } = stackSlice.actions;

export default stackSlice.reducer;
