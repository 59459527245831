  import React, { useState } from 'react';
  import { Card, Container, Row, Col, Image, Pagination, Table } from 'react-bootstrap';
  import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
  import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
  import { Accordion, AccordionItem, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'; // Import from react-accessible-accordion
  import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
  import '../../codeSnippet.css';
  import doorExperience from '../../data/DoorExperience.json';
  import doorLogo from '../../img/DoorLogo.jpg';
  import enAppSysLogo from '../../img/EnAppSysLogo.jpg';

  const MyExperience = () => {
    const priorityMap = { 'High': 3, 'Medium': 2, 'Low': 1 };
    const [activePage, setActivePage] = useState(1);
    const itemsPerPage = 10;
    const [sortKey, setSortKey] = useState('complexity');
    const [sortOrder, setSortOrder] = useState('asc');

    const sortTickets = key => {
      const order = sortKey === key && sortOrder === 'asc' ? 'desc' : 'asc';
      setSortKey(key);
      setSortOrder(order);
    }

    const sortedExperience = [...doorExperience].sort((a, b) => {
      let valA = a[sortKey], valB = b[sortKey];
    
      if (sortKey === 'priority') {
        valA = priorityMap[a.priority] || 0;
        valB = priorityMap[b.priority] || 0;
      } else if (sortKey === 'dateCompleted') {
        valA = a[sortKey] || 0;
        valB = b[sortKey] || 0;
      }
    
      return (valA < valB ? -1 : 1) * (sortOrder === 'asc' ? 1 : -1);
    });
    

    const indexOfLastItem = activePage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sortedExperience.slice(indexOfFirstItem, indexOfLastItem);

    let items = [];
    for (let number = 1; number <= Math.ceil(doorExperience.length / itemsPerPage); number++) {
      items.push(
        <Pagination.Item key={number} active={number === activePage} onClick={() => setActivePage(number)}>
          {number}
        </Pagination.Item>,
      );
    }

    const getSortIcon = key => {
      if (sortKey !== key) {
        return <FaSort />;
      }
      return sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />;
    }

    const formatDate = (timestamp) => {
      if (typeof timestamp === 'number' && !isNaN(timestamp)) {
        const date = new Date(timestamp * 1000);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      } else {
        return 'Date not available';
      }
    };
    
    const MyCodeSnippet = () => {
      const codeString = `
    public void addUsers(List<User> users) {
        usersCache.addAll(users);
        for (User user : users) {
          usersCacheMap.put(user.getUsername(), user);
        }
        LOG.info("Users cache has current size of " + usersCache.size());
        LOG.info("Users cache Map has current size of " + usersCacheMap.size());
    }
    
    public User getUser(String username) {
        return usersCacheMap.getOrDefault(username, fetchUserFromDbAndUpdateCache(username));
    }
    
    private User fetchUserFromDbAndUpdateCache(String username) {
        User user = awsService.fetchUserByUsername(username);
        if (user != null) {
            usersCache.add(user);
            usersCacheMap.put(username, user);
        }
      return user;
    }`;
    
      return (
        <div style={{ backgroundColor: '#2B2B2B', padding: '20px', borderRadius: '8px', overflowX: 'auto' }}>
          <SyntaxHighlighter language="java" style={darcula}>
            {codeString}
          </SyntaxHighlighter>
        </div>
      );
    };

    return (
      <Container>
        <Accordion allowZeroExpanded>
          <AccordionItem>
            <AccordionItemButton>
              <Row className='mb-4'>
                  <Col>
                    <Image src={doorLogo} className="profile-logo" square alt="Door Ventures Logo" fluid/>
                  </Col>
                  <Col className="align-items-start" xs={12} md={9} lg={10}>
                    <h3>Door Ventures Inc.</h3>
                    <p>Making Information Digital, Democratic and Global. Based in London and Baltimore, Door brings together experienced professionals in asset & wealth management and fintech to transform the archaic due diligence process and simplify a complex industry.</p>
                    <p>Door has its own proprietary software, and through feedback from clients and testing, it requires maintenance and new features implementation.</p>
                    <p>I was employed as a back-end Java Software Developer, starting with Selenium and Cucumber for maintaining and writing new tests, and taking on more complex tasks as my knowledge of the codebase grew.</p>
                    <p><strong>Click for more information.</strong></p>
                  </Col>
                </Row>
            </AccordionItemButton>
            <AccordionItemPanel>
              <Card.Body>
                <Table hover className="my-experience-table">
                  <thead>
                    <tr>
                      <th md={4} onClick={() => sortTickets('complexity')}>
                        <h5>Sort by complexity {getSortIcon('complexity')}</h5>      
                      </th>
                      <th md={4} onClick={() => sortTickets('dateCompleted')}>
                        <h5>Sort by most recent {getSortIcon('dateCompleted')}</h5>
                      </th>
                      <th md={4} onClick={() => sortTickets('priority')}>
                        <h5>Sort by priority {getSortIcon('priority')}</h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>   
                    {currentItems.map((experience, index) => (
                      <tr key={index}>
                        {console.log("experience:", experience)}
                        <td>
                          <h6>Ticket Type: {experience.type} - {experience.id}</h6>
                          <h6>Priority: {experience.priority}</h6>
                          <p>{experience.shortDescription}</p>
                        </td>
                        <td colSpan="2"> 
                          {console.log("experience.dateCompleted", experience.dateCompleted)}
                          <h6>Date of ticket: {formatDate(experience.dateCompleted)}</h6>
                          <p>{experience.fixDescription}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination className="justify-content-end">{items}</Pagination>
              </Card.Body>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemButton>
              <Row className='mb-4'>
                  <Col>
                    <Image src={enAppSysLogo} className="profile-logo" square alt="EnAppSys part of Montel Group Logo" fluid/>
                  </Col>
                  <Col className="align-items-start" xs={12} md={9} lg={10}>
                    <h3>EnAppSys - Energy Insight.</h3>
                    <p>EnAppSys offers data about and insights into the energy industry. Their data platform encompasses over 40 billion data items and ingests 22 terabytes of new information every month.</p>
                    <p>EnAppSys has a secure login site and an established team of experienced developers. My role was to create a new system that leveraged data stored in DynamoDB.</p>
                    <p>Using Java (Corretto), Spring Boot, FasterXML, Gradle and AWS (Predominently DynamoDb) I began creating the new 'V2' API feature. The main feature of the project, to keep speed high, was to cache the data and only persist updates when they were necessary.</p>
                    <p><strong>Click for more information.</strong></p>
                  </Col>
                </Row>
            </AccordionItemButton>
            <AccordionItemPanel>
              <Card.Body>
                <h4>Project Details</h4>
                <h4>Key Contributions & Learnings</h4>
                <ul>
                  <li>Initiated the project by setting up a Gradle and Spring Boot Java environment, establishing a solid foundation for application development.</li>
                  <li>Developed RESTful endpoints for user and group management, utilizing Spring Boot's capabilities for efficient backend services.</li>
                  <li>Implemented business logic for handling hierarchical group structures, demonstrating an understanding of advanced Java concepts.</li>
                  <li>Integrated AWS DynamoDB for data storage, gaining practical experience with NoSQL databases and AWS cloud services.</li>
                  <li>Designed and applied caching strategies using a custom cache service to enhance application performance and reduce database load.</li>
                  <li>Conducted CRUD operations with DynamoDB, enhancing my understanding of database interactions and data modeling.</li>
                  <li>Utilized AWS SDK for Java to interact with DynamoDB, furthering my skills in cloud-based application development.</li>
                  <li>Engaged with AWS services for user authentication and authorization, expanding my knowledge of security best practices.</li>
                  <li>Created an internal object mapping utility to convert between complex domain objects, improving code maintainability and object management.</li>
                  <li>Performed error handling and logging to ensure application reliability and facilitate troubleshooting.</li>
                  <li>Adopted a proactive learning approach by reviewing the main platform codebase and EnAppSys' core library, understanding new technologies and frameworks.</li>
                </ul>
                <h5>Key Features:</h5>
                <ul>
                  <li>Automated initial cache population from DynamoDB at startup, ensuring immediate data availability.</li>
                  <li>Dynamic cache updates reflecting real-time changes in the database, maintaining data integrity and freshness.</li>
                  <li>Strategic data retrieval from cache to expedite application response times.</li>
                  <li>Implementation of fallback strategies for cache misses, including fetching from DynamoDB and updating the cache accordingly.</li>
                  <li>Efficient memory management to control cache size and implement data eviction policies, optimizing resource utilization.</li>
                </ul>

              <h5>Code Snippet: Cache Service Implementation</h5>
			  <div class = "codeContainer">
				{MyCodeSnippet()}  
			  </div>
              <p>This caching system was pivotal in ensuring our application remained performant and responsive, despite the heavy load of user and group management operations.</p>
              <p>These experiences have been key in my development as a software engineer, providing a strong foundation in Java, AWS, and Spring Boot, and preparing me for future challenges in software development.</p>
              </Card.Body>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </Container>
    );
  }

  export default MyExperience;
