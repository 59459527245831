// src/components/Header.js
import React from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="header bg-dark text-light py-3">
      <Container>
        <Row className="justify-content-between align-items-end">
          <Col xs={12} md={4}>
            <Link to="/" className='nav-link text-white'>
              <h1>Duncan Skilton</h1>
              <p>Welcome to my Dev space!</p>
            </Link>
          </Col>
          <Col xs={12} md={6} className="text-md-right">
            <Nav className="justify-content-md-end">
              <Link to="/stack" className="nav-link text-white">My Stack</Link>
              <Link to="/experience" className="nav-link text-white">My Experience</Link>
            </Nav>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
